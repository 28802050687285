<template>
    <v-expansion-panel>
        <v-expansion-panel-header hide-actions class="text-subtitle-1 primary--text pb-6">
            <div>
                <v-icon color="primary" size="18" class="mr-2">security</v-icon
                >{{ $t('vvtTom.confidentialityMeasures.headline') }}
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-col cols="12">
                <v-card v-if="!preview || accessControlModel.length" class="default mb-3" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.confidentialityMeasures.accessControl.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="accessControlItems"
                            v-model="accessControlModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.confidentialityMeasures.accessControl.label')"
                            :hint="!disableFreetext ? $t('vvtTom.confidentialityMeasures.accessControl.hint') : null"
                            :error-messages="errors.collect(accessControlName)"
                            :data-vv-name="accessControlName"
                            :item-value="['id', 'clientId']"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in accessControlModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="!preview || systemControlModel.length" class="default mb-3" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.confidentialityMeasures.systemControl.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="systemControlItems"
                            v-model="systemControlModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.confidentialityMeasures.systemControl.label')"
                            :hint="!disableFreetext ? $t('vvtTom.confidentialityMeasures.systemControl.hint') : null"
                            :error-messages="errors.collect(systemControlName)"
                            :data-vv-name="systemControlName"
                            :item-value="['id', 'clientId']"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in systemControlModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="!preview || volumeControlModel.length" class="default mb-3" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.confidentialityMeasures.volumeControl.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="volumeControlItems"
                            v-model="volumeControlModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.confidentialityMeasures.volumeControl.label')"
                            :hint="!disableFreetext ? $t('vvtTom.confidentialityMeasures.volumeControl.hint') : null"
                            :error-messages="errors.collect(volumeControlName)"
                            :data-vv-name="volumeControlName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in volumeControlModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="!preview || separationControlModel.length" class="default" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.confidentialityMeasures.separationControl.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="separationControlItems"
                            v-model="separationControlModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.confidentialityMeasures.separationControl.label')"
                            :hint="!disableFreetext ? $t('vvtTom.confidentialityMeasures.separationControl.hint') : null"
                            :error-messages="errors.collect(separationControlName)"
                            :data-vv-name="separationControlName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in separationControlModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import ModelMixin from '@/components/vvt/ModelMixin';
export default {
    name: 'VvtTomConfidentialityMeasures',
    mixins: [ModelMixin],
    props: {
        value: {
          type: Object,
          default: () => {
              return {
                  accessControl: [],
                  systemControl: [],
                  volumeControl: [],
                  separationControl: [],
              }
          }
        },
        accessControlItems: {
          type: Array,
          default: () => []
        },
        systemControlItems: {
          type: Array,
          default: () => []
        },
        volumeControlItems: {
          type: Array,
          default: () => []
        },
        separationControlItems: {
          type: Array,
          default: () => []
        },
        itemsDispatcher: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: false,
        },
        disableFreetext: {
            type: Boolean,
            default: false,
        },
    },
    inject: {
        $validator: '$validator',
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/Tom/en.json'),
            de: require('@/locales/vvt/Tom/de.json'),
        },
    },
    data() {
        return {
            accessControlName: 'confidentiality-measures-access-control',
            systemControlName: 'confidentiality-measures-system-control',
            volumeControlName: 'confidentiality-measures-volume-control',
            separationControlName: 'confidentiality-measures-separation-control',
            accessControlObject: null,
            systemControlObject: null,
            volumeControlObject: null,
            separationControlObject: null,
        };
    },
    computed: {
        accessControlModel: {
            get() {
                return this.getValue(
                    this.accessControlItems,
                    this.value.accessControl
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.systemControlObject = this.systemControlModel;
                this.volumeControlObject = this.volumeControlModel;
                this.separationControlObject = this.separationControlModel;
                this.accessControlObject = this.setValue(
                    this.accessControlItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'confidentialityMeasures',
                        subField: 'accessControl',
                    }
                );
                this.transformAndEmit();
            },
        },
        systemControlModel: {
            get() {
                return this.getValue(
                    this.systemControlItems,
                    this.value.systemControl
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.accessControlObject = this.accessControlModel;
                this.volumeControlObject = this.volumeControlModel;
                this.separationControlObject = this.separationControlModel;
                this.systemControlObject = this.setValue(
                    this.systemControlItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'confidentialityMeasures',
                        subField: 'systemControl',
                    }
                );
                this.transformAndEmit();
            },
        },
        volumeControlModel: {
            get() {
                return this.getValue(
                    this.volumeControlItems,
                    this.value.volumeControl
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.accessControlObject = this.accessControlModel;
                this.systemControlObject = this.systemControlModel;
                this.separationControlObject = this.separationControlModel;
                this.volumeControlObject = this.setValue(
                    this.volumeControlItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'confidentialityMeasures',
                        subField: 'volumeControl',
                    }
                );
                this.transformAndEmit();
            },
        },
        separationControlModel: {
            get() {
                return this.getValue(
                    this.separationControlItems,
                    this.value.separationControl
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.accessControlObject = this.accessControlModel;
                this.systemControlObject = this.systemControlModel;
                this.volumeControlObject = this.volumeControlModel;
                this.separationControlObject = this.setValue(
                    this.separationControlItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'confidentialityMeasures',
                        subField: 'separationControl',
                    }
                );
                this.transformAndEmit();
            },
        },
    },
    methods: {
        transformAndEmit() {
            const obj = {
                accessControl: this.accessControlObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
                systemControl: this.systemControlObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
                volumeControl: this.volumeControlObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
                separationControl: this.separationControlObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
            };
            this.$emit('input', obj);
        },
    },
};
</script>
